import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
// import Image from "../components/image"
import { Image } from 'antd';
import SEO from "../components/seo"

// import { DatePicker } from 'antd';

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />

    {/* <img src="http://picknotebook.com/blog/wp-content/uploads/2015/11/best-laptop-for-programming.jpg" /> */}
    <h1>Welcome to joost.cx</h1>
    {/* <DatePicker /> */}
    <p>Coding, Devops, Startups, 3D / Gamedev, ...</p>
    <p>Check out my <Link to="/posts">posts</Link> or <Link to="/contact">contact me</Link></p>
    <Image width={200} src="https://github.com/joostfaassen.png" preview={false} />
  </Layout>
)


export default IndexPage
